/** @jsx jsx */

import React from 'react';
import { jsx, Image } from 'theme-ui';
import SEO from '../components/seo';
import Layout from '../components/layout';

import './styles.scss';

export default function TermsAndConditions() {
  return (
    <Layout>
      <SEO title="Terms & Conditions" />
      <div className="page-container mb-4">
        <div class="mt-4 mb-4">
          <h1 className="text-center">TERMS & CONDITIONS</h1>
        </div>
        <div className="rich-text-block-2 w-richtext">
          <p>
            <strong>TERMS OF USE</strong>
          </p>
          <br />
          <p>
            <strong>Tut Technologies LLC, dba MoonAlgo</strong>
          </p>
          <br />
          <p>
            <em>Last updated: 03/21/24</em>
          </p>
          <p>
            <strong>
              <br />
            </strong>
            The Services are provided by MoonAlgo Co., an Arizona corporation
            with a principal place of business located in Gilbert, AZ. If you
            have requests concerning these Terms of Use, please contact us at
            contact@moonalgo.com.
          </p>
          <br />
          <p>
            <strong>
              PLEASE BE ADVISED: THIS AGREEMENT CONTAINS PROVISIONS (SEE SECTION
              8.2) THAT GOVERN HOW CLAIMS YOU AND MoonAlgo HAVE AGAINST EACH
              OTHER CAN BE BROUGHT. THESE PROVISIONS WILL, WITH LIMITED
              EXCEPTION, REQUIRE YOU TO SUBMIT CLAIMS YOU HAVE AGAINST MoonAlgo,
              TO BINDING AND FINAL ARBITRATION ON AN INDIVIDUAL BASIS, NOT AS A
              PLAINTIFF OR CLASS MEMBER IN ANY CLASS, GROUP OR REPRESENTATIVE
              ACTION OR PROCEEDING.{' '}
            </strong>
          </p>
          <br />
          <br />
          <br />
          <p>
            <strong>SECTION I: DEFINITIONS, AGREEMENT TO BE BOUND</strong>
          </p>
          <p>1.1 Definitions.</p>
          <br />
          <p>
            References to the “Script” mean the script developed, owned, and
            operated by us, including but not limited to “MoonAlgo,” available
            to registered Users of our Services and available for access via the
            TradingView mobile software application.
          </p>
          <p>
            References to “Dispute” mean any claim, conflict, controversy,
            disagreement between the Parties arising out of, or related in any
            way to, these Terms (or any Terms, supplement or amendment
            contemplated by these Terms,) including, without limitation, any
            action in tort, contract or otherwise, at equity or at law, or any
            alleged breach, including, without limitation, any matter with
            respect to the meaning, effect, validity, performance, termination,
            interpretation or enforcement of these Terms or any Terms
            contemplated by the Terms.
          </p>
          <br />
          <p>
            References to “Material Breach” mean any breach of these Terms upon
            the occurrence of which a reasonable person in the position of the
            non-breaching Party would wish to immediately terminate these Terms
            because of that breach.
          </p>
          <br />
          <p>
            References to the “Services” mean, collectively, the Web site,
            Script, and any and all features thereof.{' '}
          </p>
          <p>
            References to the “Terms” and/or “Agreement,” mean this, these Terms
            of Use as set forth herein.{' '}
          </p>
          <br />
          <p>
            References to “us,” “we,” “our,” and/or “MoonAlgo,” mean MoonAlgo
            Co., an Arizona corporation, including any and all trade names
            utilized thereby.{' '}
          </p>
          <p> </p>
          <p>
            References to the “Web site” mean, collectively, the Web site
            bearing the URL www.moonalgo.com as well as any other site or social
            media page operated by us.
          </p>
          <p> </p>
          <p>
            References to “you,” and/or “User,” mean a general user of the Web
            site and/or user of our Services.
          </p>
          <br />
          <br />
          <br />
          <p>1.2. Agreement to be Bound.</p>
          <br />
          <p>
            The following Terms of Use, together with any relevant information
            set out via the Services, including any features and services
            available, such as RSS feeds, podcasts, videos and photographs,
            publications, and other materials are subject to the Terms of Use
            set forth below. Please read them carefully as any of use of the
            Services constitutes an agreement, without acceptance, to be bound
            thereby by the User. By using the Services, you represent that you
            are at least eighteen (18) years old or have written, parental or
            guardian consent, have read and understand the Terms of Use, and
            that you agree to be bound by these Terms of Use as set forth below.{' '}
          </p>
          <p> </p>
          <p>
            These Terms of Use are subject to the Privacy Policy, which also
            governs your use of the Services.
          </p>
          <br />
          <br />
          <p> </p>
          <p>
            <strong>SECTION II: GENERAL PROVISIONS</strong>
          </p>
          <p>2.1. Accuracy, Completeness and Timeliness of Information.</p>
          <br />
          <p>
            We are not responsible if information made available via the
            Services is not accurate, complete, or current. You acknowledge that
            the Services are provided for general information only and should
            not be relied upon or used as the sole basis for making decisions
            without consulting primary, more accurate, more complete, or more
            timely sources of information. You acknowledge further that any
            reliance on the Services is at your own risk.
          </p>
          <br />
          <br />
          <br />
          <p>2.2. Errors.</p>
          <p>
            <br />
            We do not warrant that any errors in the Services will be corrected.{' '}
          </p>
          <br />
          <br />
          <br />
          <p>2.4. Modifications and Changes to Terms of Use.</p>
          <br />
          <p>
            We may modify, add to, suspend or delete these Terms of Use or other
            agreements, in whole or in part, in our sole discretion at any time,
            with such modifications, additions or deletions being immediately
            effective upon their posting to the Services and our emailing of a
            notice to registered Users. Your continued use of our Services,
            after modification, addition, or deletion of these Terms of Use
            shall be deemed to constitute acceptance by you of the modification,
            addition, or deletion.{' '}
          </p>
          <br />
          <br />
          <br />
          <p>2.5. Modifications and Changes.</p>
          <br />
          <p>
            We may modify, add to, suspend, or delete any aspect of the Services
            offered by us, in whole or in part, at our sole discretion at any
            time, with such modifications, additions or deletions being
            immediately effective. Such modifications, additions or deletions
            may include but are not limited to content offered, hours of
            availability, and equipment needed for access or use.{' '}
          </p>
          <br />
          <br />
          <br />
          <p>2.6. Access.</p>
          <br />
          <p>
            Though we try to make the Services available twenty-four (24) hours
            a day, seven (7) days a week, except for planned down-time for
            maintenance, we do not warrant that the Services will be at all
            times available.{' '}
          </p>
          <br />
          <p>
            We do not warrant that your computer, tablet, smartphone, or
            hardware will be able to access and/or support the Services.
          </p>
          <br />
          <br />
          <br />
          <p>
            2.7. Right of Refusal, Limitation, Discontinuation, and Termination.
          </p>
          <br />
          <p>
            We reserve the right to refuse to provide access to the Services for
            any reason at any time in our sole and exclusive discretion. We may,
            in our sole and exclusive discretion, limit or cancel a User Account
            for any reason whatsoever. In the event that we make a change to or
            cancel an Account, we will attempt to notify you by contacting the
            e-mail and/or billing address/phone number provided at the time your
            Account was created; however, the failure to do so shall not result
            in any liability, including liability for lost data or rewards
            points deleted as a result of the Account termination. &nbsp;
          </p>
          <br />
          <br />
          <br />
          <p>2.8. Prohibited Uses.</p>
          <br />
          <p>
            In addition to the other restrictions on use set forth herein, you
            agree and acknowledge that you shall not use the Services: (a) for
            any unlawful purpose; (b) to solicit Users to perform or participate
            in any unlawful acts or to engage in acts that are unrelated to the
            purpose(s) of the Services; (c) to violate any international or
            governmental regulations, rules, laws, or local ordinances; (d) to
            infringe upon or violate our intellectual property rights or the
            intellectual property rights of others; (e) to harass, abuse,
            insult, harm, defame, slander, disparage, intimidate, or
            discriminate based on gender, sexual orientation, religion,
            ethnicity, race, age, national origin, or disability; (f) to submit
            false or misleading information; (g) to upload or transmit viruses
            or any other type of malicious code that will or may be used in any
            way that will affect the functionality or operation of the Services;
            (h) to collect or track the personal information of others; (i) to
            spam, phish, pharm, pretext, spider, crawl, or scrape; (j) for any
            obscene or immoral purpose; (k) to impersonate any person or entity;
            (l) forge headers or otherwise manipulate identifiers in order to
            disguise the origin of any information transmitted through the
            Services; (m) “frame” or “mirror” any part of the Services, without
            our prior written authorization or use meta tags or code or other
            devices containing any reference to us in order to direct any person
            to any other service or Web site for any purpose; (n) modify, adapt,
            translate, reverse engineer, decipher, decompile, separate, or
            otherwise disassemble any portion of the Services or any software
            used on or for the Services; or (o) to interfere with or circumvent
            the security features of the Services. We reserve the right to
            terminate your use of the Services for violating any of the
            prohibited uses or for any other reason in our sole and exclusive
            decision.{' '}
          </p>
          <br />
          <br />
          <br />
          <p>
            2.9. No Financial Advice Rendered; Reliance on Information at
            Member’s Own Risk.
          </p>
          <br />
          <p>
            We are not certified financial advisors nor do we provide financial
            advice via our Services. Rather, the information made available via
            the Services is intended as an informational tool. Users agree and
            acknowledge that, due to the volatile and unpredictable nature of
            the industry in which the Services operate, success and profit is
            not guaranteed. Rather, success is dependent on User’s own
            understanding of the content provided and markets. The information
            provided is not intended to be a replacement for a mathematics,
            finance or business education. Information should not be relied upon
            or used as the sole basis for making decisions regarding trading and
            the markets.{' '}
          </p>
          <br />
          <br />
          <br />
          <p>
            <strong>SECTION III: ACCOUNTS</strong>
            <br />
            3.1. Accounts.
          </p>
          <br />
          <p>
            Users shall be given the opportunity to register via an online
            registration form to create a User account, (hereinafter your
            “Account,”) that will allow you to receive information from us
            and/or to participate in certain features of the Services. We will
            use the information you provide in accordance with our Privacy
            Policy. By registering with us, you represent and warrant that all
            information you provide on the registration form is current,
            complete and accurate to the best of your knowledge. You agree to
            maintain and promptly update your registration information so that
            it remains current, complete and accurate. During the registration
            process, you may be required to choose a password. You acknowledge
            and agree that we may rely on this password to authorize use of your
            Account. You are responsible for all use of your Account, regardless
            of whether you authorized such access or use, and for ensuring that
            all use of your Account complies fully with the provisions of these
            Terms of Use. Accounts may be cancelled at any time via the Account
            settings. You are responsible for maintaining the confidentiality of
            your account and are fully responsible for any and all activities
            that occur under your account. You agree to (a) immediately notify
            us of any unauthorized use of your Account or any other breach of
            security, and (b) ensure that you log out of your Account at the end
            of each session.{' '}
          </p>
          <br />
          <br />
          <br />
          <p>3.2. Multiple Accounts, Transfer Prohibited.</p>
          <br />
          <p>
            You agree you shall not have more than one (1) Account and shall not
            sell, trade, or transfer that Account to any other person or entity.{' '}
          </p>
          <br />
          <br />
          <br />
          <p>3.3. Interactive Features Guidelines.</p>
          <br />
          <p>
            The Services may contain the ability to communicate with other Users
            on one or more platforms, comments sections, discussion forums, Web
            sites, landing pages, social media outlets, sales channels, and/or
            other interactive features, such as our Discord group, ("Interactive
            Areas,”) in which Users may communicate. By participating in
            Interactive Areas, you agree and acknowledge to the following
            community guidelines. Violating these rules may lead to suspension
            of your account or your permanent exclusion from the Services. If
            you are permanently excluded from the Services, your Account will be
            terminated, and you will not be allowed to use our Services again.{' '}
          </p>
          <br />
          <p>
            Enforcement of these rules is entirely at the discretion of
            MoonAlgo, and where we think the actions of users may affect the
            enjoyment or safety of others in our community, MoonAlgo reserves
            the right to take necessary action, including but not limited to
            reporting you to law enforcement. For the avoidance of doubt, any
            content or activity featuring, encouraging, offering, or soliciting
            illegal activity is prohibited. We can and will notify law
            enforcement of any illegal activity found taking place through our
            Services.
          </p>
          <br />
          <p>
            We reserve the right to regularly update these guidelines without
            notice based on the evolution of the MoonAlgo community and the
            services we provide.{' '}
          </p>
          <br />
          <p>By creating an Account, you agree that you:</p>
          <br />
          <p>
            Shall not upload, distribute, or otherwise publish any libelous,
            defamatory, obscene, pornographic, abusive, or otherwise illegal
            material; and
          </p>
          <br />
          <p>
            Shall not threaten or verbally abuse another User, use defamatory
            language, or deliberately disrupt discussions with repetitive
            messages, meaningless messages or "spam”; and
          </p>
          <br />
          <p>
            Shall not to use language that abuses or discriminates on the basis
            of race, religion, nationality, gender, sexual preference, age,
            region, disability, etc. Hate speech of any kind is grounds for
            immediate and permanent suspension of access to all or part of the
            Services, without recourse; and
          </p>
          <br />
          <p>
            Shall not personally attack another User. Personal attacks are a
            direct violation of these Terms of Use and are grounds for immediate
            and permanent suspension of access to all or part of the Services,
            without recourse; and
          </p>
          <br />
          <p>
            Shall not distribute or otherwise publish any material containing
            any solicitation of funds, advertising, or solicitation for goods or
            services; and
          </p>
          <br />
          <p>
            Shall not upload, post or otherwise transmit any content that
            violates any law or engage in activity that would constitute a
            criminal offense or give rise to a civil liability; and
          </p>
          <br />
          <p>
            Shall not post unauthorized commercial communications (such as
            spam); and{' '}
          </p>
          <br />
          <p>
            Shall not upload, post or otherwise transmit any content that
            advocates or provides instruction on illegal activity or discuss
            illegal activities with the intent to commit them; and
          </p>
          <p> </p>
          <p>
            Shall not upload, post, or otherwise transmit content that does not
            generally pertain to the designated topic or theme of the Services;
            and
          </p>
          <br />
          <p>
            Shall not impersonate any person or entity, including, but not
            limited to, any of our employees, or falsely state or otherwise
            misrepresent your affiliation with any person or entity; and
          </p>
          <br />
          <p>
            Shall not engage in unlawful multi-level marketing, such as a
            pyramid scheme; and
          </p>
          <br />
          <p>
            Shall not upload, post or otherwise transmit any content, software
            or other materials which contain a virus or other harmful or
            disruptive component; and
          </p>
          <br />
          <p>
            Shall not interfere with or disrupt the Services or the servers or
            networks connected to the same, or disobey any requirements,
            procedures, policies, or regulations of networks connected to the
            Services; and{' '}
          </p>
          <br />
          <p>
            Shall not share or otherwise distribute or make public personal
            information of other Users and/or individuals or entities; and
          </p>
          <br />
          <p>
            Shall not facilitate or encourage any violations of these Terms of
            Use or our policies.
          </p>
          <br />
          <p>
            Users agree further and acknowledge that any profile they create may
            be edited, removed, modified, published, transmitted, and displayed
            by us, and they waive any rights they may have in having the
            material altered or changed in a manner not agreeable to them.{' '}
          </p>
          <br />
          <br />
          <br />
          <p>3.4. Rights in Submissions.</p>
          <br />
          <p>
            Should you submit, display, publish, or otherwise post any content
            to us, (hereinafter “Submissions,”) you agree to grant to us and our
            partners and affiliates a limited, non-exclusive, sub-licensable,
            worldwide, fully-paid, royalty free license to use, modify, publicly
            perform, publicly display, reproduce, and distribute such
            Submissions in any and all media now known or hereinafter developed
            for hosting, indexing, caching, distributing, tagging, marketing,
            and for all other lawful purposes without the requirement to make
            payment to or seek permission from you or to any third-party.{' '}
          </p>
          <br />
          <p>
            You represent and warrant that you own or have a valid license to
            use any and all Submissions and otherwise have the right to grant
            the license set forth herein, and the displaying, publishing or
            posting of any Submissions does not and will not violate the privacy
            rights, publicity rights, copyrights, trademark rights, patents,
            contract rights, or any other intellectual property rights or other
            rights of any person or entity.{' '}
          </p>
          <br />
          <br />
          <br />
          <p>3.5. Right to Monitor; Suspend. </p>
          <br />
          <p>
            We shall have the right to monitor your Account in our sole and
            exclusive discretion. If we determine that you have violated the
            Account guidelines set forth in Section 3.3 or any other provisions
            of these Terms, we will restrict your access to the interactive
            services (chat rooms) or otherwise block your User Account for a
            minimum period of twenty-four (24) hours. In the event of egregious
            violations, your Account may be blocked for a longer period or
            permanently disabled, in our sole discretion and without recourse by
            you. We may consider appeals of suspensions and permanent exclusions
            based on our internal review. Our customer support and moderation
            teams review all requests for reinstatement, and we process these
            along with all other support requests in our entire discretion. We
            are not required to provide a reason for our decision and our
            decision on your appeal is final.
          </p>
          <br />
          <br />
          <br />
          <p>3.6. Reporting Abuse. </p>
          <br />
          <p>
            If you become aware of a violation of the Account guidelines set
            forth in Section 3.3 or any other provisions of these Terms by
            another User, you are encouraged to promptly notify us by writing to
            us at contact@moonalgo.com. All reports will be held in confidence
            to the extent possible.{' '}
          </p>
          <br />
          <br />
          <p>
            <br />
            <strong>SECTION IV: FEES</strong>
          </p>
          <p>4.1. Subscription Fees.</p>
          <br />
          <p>
            User wishing to subscribe to our Services must pay a Subscription
            Fee in the amount set forth on at the time of purchase. We may
            increase these Subscription Fees from time to time, in our sole and
            exclusive discretion, where such changes shall take effect upon the
            next Billing Cycle (as defined in Section 4.2.) All Subscription
            Fees are expressed in U.S. Dollars ($USD.)
          </p>
          <br />
          <p>
            <br />
          </p>
          <p>4.2 Employee Availability</p>
          <br />
          <p>
            In relation to our 24/7 guarantee, you, as the customer, are always
            welcome to reach out anytime to our Trading Pros. That being said,
            our Trading Pros are located globally and are not required by
            MoonAlgo to be online 24/7. They can be found offline or hard to
            reach during some hours of the day. If you do not receive a response
            from a MoonAlgo Trading Pro immediately, MoonAlgo is not found
            liable.
          </p>
          <br />
          <br />
          <p>4.3. Automatic Billing. </p>
          <br />
          <p>
            Except for in the event of a trial premium subscription, all
            Subscription Fees as set forth herein shall be pre-paid, in full as
            per the selected billing cycle (which may be a month, quarter, or
            year, the “Billing Cycle,”) on the first (1st) day of the Billing
            Cycle, where the first (1st) day of the first (1st) Billing Cycle
            shall be the date upon which User registers for a premium
            subscription. User shall be billed on the same day thereafter at the
            beginning of each Billing Cycle.
          </p>
          <br />
          <br />
          <br />
          <p>4.3. Form of Payment. </p>
          <br />
          <p>
            Payment shall be made automatically via our payment processor,
            Stripe, by automatic debit of the major credit or debit card
            provided by User upon Account registration or as later updated by
            User.{' '}
          </p>
          <br />
          <br />
          <br />
          <p>4.4. Refunds.</p>
          <br />
          <p>
            Due to the electronic nature of the services that we provide, in no
            event other than material breach or of these Terms by us or a defect
            in the Services, shall a refund be granted, in whole or in part, for
            any reason or no reason whatsoever, including but not limited to
            User’s cancellation prior the end of the Billing Cycle and/or User’s
            failure to utilize the Services.
          </p>
          <br />
          <br />
          <p>4.5 Disclaimer</p>
          <br />
          <p>
            All purchases acknowledge they have read the readily available
            Dislclaimer. MoonAlgo&nbsp;will not be held liable for any losses
            users take from any instrument we provide.
          </p>
          <br />
          <br />
          <ol role="list">
            <li>
              <strong>
                <br />
                INTELLECTUAL PROPERTY; PRIVACY
              </strong>
            </li>
          </ol>
          <p>5.1. Intellectual Property Rights Not Waived.</p>
          <br />
          <p>
            This is an Agreement for access to and use of the Services, and you
            are not granted a license to any software or intellectual property
            by these Terms of Use. The Services are protected by U.S. and, where
            applicable, international intellectual property laws. The Services
            belong to us and are the property of us or our licensors (if any).{' '}
          </p>
          <br />
          <p>
            Furthermore, all material displayed or transmitted via the Services,
            including but not limited to text, photographs, images,
            illustrations, video clips, audio clips, and graphics, (hereinafter
            “Materials,”) are owned by us and are protected by U.S. and
            international copyright, trademarks, service marks, and other
            proprietary rights, laws, and treaties.
          </p>
          <br />
          <p>
            Except as provided, you may not copy, reproduce, publish, transmit,
            transfer, sell, rent, modify, create derivative works from,
            distribute, repost, perform, display, or in any way commercially
            exploit the Materials, nor may you infringe upon any of the
            copyrights or other intellectual property rights contained in the
            Materials. You may not remove or alter, nor cause to be removed or
            altered, any copyright, trademark, or other proprietary notices or
            visual marks and logos from the Materials. You shall not send
            MoonAlgo alerts to another server, community, or anything outside of
            MoonAlgo Discord.
          </p>
          <br />
          <p>
            You may make a single print copy of any Materials provided by us for
            personal, non-commercial use only, provide that you do not remove
            nor cause to be removed any copyright, trademarks, or other
            proprietary notices or visual marks or logos from the Materials. You
            may not archive or retain any of the Materials without our express
            written permission. All requests for archiving, republication or
            retention of any part of the Materials must be in writing to us and
            must clearly state the purpose and manner in which the Material will
            be used. Requests for permission to archive, retain, or republish
            any part of the Materials may be submitted to contact@moonalgo.com.
          </p>
          <br />
          <p>
            You acquire no rights or licenses whatsoever in the Materials other
            than the limited rights to use the Services in accordance with these
            Terms of Use. Any of the Materials accessed or downloaded from this
            site must be accessed or downloaded in accordance with the Terms of
            Use specified in this Agreement. We reserve any rights not expressly
            granted under these Terms of Use.
          </p>
          <br />
          <br />
          <br />
          <p>5.2. Feedback.</p>
          <br />
          <p>
            You may have the opportunity to provide reviews, suggestions, ideas,
            and feedback, (hereinafter, collectively, “Feedback.”) Should you so
            provide such Feedback you grant us sole ownership of the same, which
            includes, without limitation, the right for us or any third party we
            designate, to use, copy, transmit, excerpt, publish, distribute,
            publicly display, publicly perform, create derivative works of,
            host, index, cache, tag, encode, modify and adapt (including without
            limitation the right to adapt to streaming, downloading, broadcast,
            mobile, digital, thumbnail, scanning or other technologies) in any
            form or media now known or hereinafter developed. All such Feedback
            shall be treated as non-confidential.
          </p>
          <p>
            If it is determined that you retain moral rights (including rights
            of attribution or integrity) in the content submitted by you, you
            hereby declare that (a) you do not require that any
            personally-identifying information be used in connection with the
            content, or any derivative works of or upgrades or updates thereto;
            (b) you have no objection to the publication, use, modification,
            deletion and exploitation of the content by us or our licensees,
            successors and assigns; (c) you forever waive and agree not to claim
            or assert any entitlement to any and all moral rights of an author
            in any of the content; and (d) you forever release us, and our
            licensees, successors and assigns, from any claims that you could
            otherwise assert against us by virtue of any such moral rights. You
            also permit any other User to access, view, store or reproduce the
            content for that User's personal use.{' '}
          </p>
          <p>
            Notwithstanding the foregoing, you acknowledge that your Feedback
            may contain concepts, ideas, materials, proposals, suggestions and
            the like relating to the Services, (hereinafter your “Ideas.”) With
            respect to your Ideas you acknowledge that: (a) we receive numerous
            submissions from many parties and/or may have
            independently-developed and/or considered ideas similar to your
            Ideas, and that our review of your Ideas is not an admission of
            novelty, priority or originality; and (b) our use of any ideas
            similar to your Ideas, whether based on your Feedback or
            Submissions, provided to us by Third Parties, or
            independently-developed or considered by us, shall be without
            obligation to you.
          </p>
          <br />
          <br />
          <br />
          <p>
            <strong>
              SECTION VI: THIRD-PARTY ADVERTISEMENTS, PROMOTIONS, AND LINKS
            </strong>
          </p>
          <p>6.1. Third-Party Advertisements and Promotions.</p>
          <br />
          <p>
            We may, from time to time, run advertisements and promotions from
            third parties via the Services. Your dealings or correspondence
            with, or participation in promotions of, advertisers other than us,
            and any terms, conditions, warranties or representations associated
            with such dealings, are solely between you and such third party. We
            are not responsible or liable for any loss or damage of any sort
            incurred as the result of any such dealings or as the result of the
            presence of third-party advertisers via the Services.
          </p>
          <br />
          <br />
          <br />
          <p>6.2. Use of Third-Party Tools.</p>
          <br />
          <p>
            We may provide you with access to third-party tools over which we
            neither monitor nor have any control nor input.
          </p>
          <br />
          <p>
            You acknowledge and agree that we provide access to such tools “as
            is” and “as available” without any warranties, representations or
            conditions of any kind and without any endorsement. We shall have no
            liability whatsoever arising from or relating to your use of
            optional third-party tools.
          </p>
          <br />
          <p>
            Any use by you of optional tools offered through the Services is
            entirely at your own risk and discretion, and you should ensure that
            you are familiar with and approve of the terms on which tools are
            provided by the relevant third-party provider(s).
          </p>
          <br />
          <p>
            We may also, in the future, offer new services and/or features
            through the Services, including but not limited to the release of
            new tools. Such new features and/or services shall also be subject
            to these Terms of Use.
          </p>
          <br />
          <br />
          <br />
          <p>6.3. Third-Party Links.</p>
          <br />
          <p>
            Certain content, products and services available via our Services
            may include materials from third parties.
          </p>
          <br />
          <p>
            Third-party links may direct you to third-party Web sites and/or
            services that are not affiliated with us. We are not responsible for
            examining or evaluating the content or accuracy, and we do not
            warrant and will not have any liability or responsibility for any
            third-party materials or Web sites and/or services, or for any other
            materials, products, or services of third parties.
          </p>
          <br />
          <br />
          <br />
          <p>
            <strong>
              SECTION VII: DISCLAIMERS; LIMITATIONS OF LIABILITY;
              INDEMNIFICATION
            </strong>
          </p>
          <p>7.1. DISCLAIMER OF WARRANTY; LIMITATION OF LIABILITY.</p>
          <br />
          <p>
            (A) YOU AGREE THAT USE OF THE SERVICES IS AT YOUR SOLE RISK. NEITHER
            US, AFFILIATES NOR ANY RESPECTIVE DIRECTORS, OFFICERS, MEMBERS,
            EMPLOYEES, CONTRACTORS, PARENTS, SUBSIDIARIES, AGENTS, THIRD-PARTY
            CONTENT PROVIDERS AND LICENSORS WARRANT THAT THE USE OF THE SERVICES
            SHALL BE UNINTERRUPTED OR ERROR FREE; NOR DO WE MAKE ANY WARRANTY AS
            TO THE RESULTS THAT MAY BE OBTAINED FROM USE OF THE SERVICES OR AS
            TO THE ACCURACY, RELIABILITY, OR CONTENT OF ANY INFORMATION
            PROVIDED.
          </p>
          <br />
          <p>
            (B) ANY INFORMATION PROVIDED VIA THE SERVICES, DOWNLOADABLE
            SOFTWARE, PRODUCTS OR OTHER MATERIALS, WITHOUT LIMITATION, IS
            PROVIDED ON AN "AS IS" BASIS WITHOUT WARRANTIES OF ANY KIND, EITHER
            EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, WARRANTIES OF
            TITLE OR IMPLIED WARRANTIES OF MERCHANTABILITY OR FITNESS FOR A
            PARTICULAR PURPOSE AGAINST INFRINGEMENT, OTHER THAN THOSE WARRANTIES
            WHICH ARE IMPLIED BY AND INCAPABLE OF EXCLUSION, RESTRICTION OR
            MODIFICATION UNDER THE LAWS APPLICABLE TO THESE TERMS OF USE.{' '}
          </p>
          <br />
          <p>
            (C) IN NO EVENT SHALL WE OR OUR AFFILIATES NOR ANY RESPECTIVE
            DIRECTORS, OFFICERS, MEMBERS, EMPLOYEES, CONTRACTORS, PARENTS,
            SUBSIDIARIES, AGENTS, THIRD-PARTY CONTENT PROVIDERS AND LICENSORS BE
            LIABLE TO YOU OR ANYONE ELSE FOR ANY LOSS OR DAMAGES WHATSOEVER,
            INCLUDING BUT NOT LIMITED TO ANY INDIRECT, SPECIAL, CONSEQUENTIAL,
            INCIDENTAL, PUNITIVE, OR OTHER DAMAGES, INCLUDING BUT NOT LIMITED TO
            PROFESSIONAL LIABILITY DAMAGES, MALPRACTICE LOSSES AND DAMAGES,
            EXEMPLARY, RELIANCE, OR CONSEQUENTIAL DAMAGES, LOSS OF PROFITS,
            PERSONAL INJURY OR DEATH, PROPERTY DAMAGE, REPUTATIONAL HARM, OR
            LOSS OF INFORMATION OR DATA, ARISING OUT OF OR RELATED TO THE USE OF
            OR INABILITY TO USE THE SERVICES.
          </p>
          <br />
          <p>
            (D) WE DISCLAIM ANY AND ALL LIABILITY OF ANY KIND FOR ANY
            UNAUTHORIZED ACCESS TO OR USE OF YOUR PERSONALLY-IDENTIFIABLE
            INFORMATION. BY UTILIZING THE SERVICES, YOU ACKNOWLEDGE AND AGREE TO
            OUR DISCLAIMER OF ANY SUCH LIABILITY. IF YOU DO NOT AGREE, YOU
            SHOULD NOT ACCESS OR OTHERWISE UTILIZE THE SERVICES.
          </p>
          <br />
          <p>
            (E) OUR LIABILITY, AND (AS APPLICABLE) OUR AFFILIATES, AND ANY
            RESPECTIVE DIRECTORS, OFFICERS, MEMBERS, EMPLOYEES, CONTRACTORS,
            PARENTS, SUBSIDIARIES, AGENTS, THIRD-PARTY CONTENT PROVIDERS AND
            LICENSORS, TO YOU OR ANY THIRD PARTIES IN ANY CIRCUMSTANCE IS
            LIMITED TO FIFTY U.S. DOLLARS AND NO/CENTS ($50.00).
          </p>
          <br />
          <p>
            (F) NO ACTION REGARDLESS OF FORM, RELATING TO THE TRANSACTION UNDER
            THIS AGREEMENT MAY BE BROUGHT BY A USER MORE THAN ONE (1) YEAR AFTER
            THE EVENT GIVING ARISE TO THE CAUSE OF ACTION HAS OCCURRED OR SHOULD
            HAVE DILIGENTLY BEEN DISCOVERED.{' '}
          </p>
          <br />
          <p>
            SOME JURISDICTIONS DO NOT ALLOW THE LIMITATION OR EXCLUSION OF
            LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO SOME OF THE
            ABOVE LIMITATIONS MAY NOT APPLY TO CERTAIN USERS.
          </p>
          <br />
          <p>
            The above limitations shall survive these Terms and inure to the
            benefit of us and our affiliates and respective directors, officers,
            employees and agents.
          </p>
          <p>
            The above limitations shall survive these Terms and inure to the
            benefit of us and our affiliates, directors, officers, members,
            employees, contractors, parents, subsidiaries, agents, third-party
            content providers, licensors, and any purchasing person or entity in
            the event that we are sold, in whole or in part, and/or
            restructured.
          </p>
          <br />
          <br />
          <br />
          <p>7.2. INDEMNIFICATION.</p>
          <br />
          <p>
            YOU AGREE TO DEFEND, INDEMNIFY, AND HOLD US HARMLESS, AS WELL OUR
            AFFILIATES, AND ANY RESPECTIVE DIRECTORS, OFFICERS, MEMBERS,
            EMPLOYEES, CONTRACTORS, PATENTS, SUBSIDIARIES, AGENTS, THIRD-PARTY
            CONTENT PROVIDERS AND LICENSORS FROM AND AGAINST ALL CLAIMS, SUITS,
            AND EXPENSES, INCLUDING ATTORNEYS' FEES, ARISING OUT OF OR RELATED
            TO (A) YOUR USE OF THE SERVICES; (B) YOUR NONCOMPLIANCE WITH OR
            BREACH OF THIS AGREEMENT, INCLUDING OUR ACCOUNT GUIDELINES; (C) YOUR
            USE OF THIRD-PARTY SERVICES, PRODUCTS, LINKS, ADVERTISEMENTS, AND/OR
            TOOLS; (D) YOUR VIOLATIONS OF ANY THIRD-PARTY RIGHTS, INCLUDING
            THIRD-PARTY INTELLECTUAL PROPERTY RIGHTS; (E) THE UNAUTHORIZED USE
            OF THE SERVICES BY ANY OTHER PERSON USING YOUR INFORMATION; OR (F)
            YOUR FAILURE TO COMPLY WITH ANY STATE, LOCAL, OR FEDERAL RULE OR
            REGULATION.
          </p>
          <br />
          <br />
          <br />
          <p>
            <strong>SECTION VIII: GOVERNING LAW; ARBITRATION</strong>
          </p>
          <p>8.1. Governing Law.</p>
          <br />
          <p>
            These Terms shall be governed and construed in accordance with the
            laws of the state of Arizona, without regard to its conflicts of law
            provisions. You agree to submit to the personal jurisdiction of the
            courts located in Maricopa County, Arizona, and any cause of action
            that relates to or arises from these Terms and/or the Services must
            be filed therein unless subject to the binding arbitration
            provisions of Section 8.2<em>, infra</em>.
          </p>
          <br />
          <br />
          <br />
          <p>8.2. Arbitration.</p>
          <br />
          <p>
            Any and all claims and disputes arising under or relating to this
            Agreement are to be settled by binding arbitration in Maricopa
            County, Arizona. The arbitration shall be conducted on a
            confidential basis pursuant to the Commercial Arbitration Rules of
            the American Arbitration Association. &nbsp;Any decision or award as
            a result of any such arbitration proceeding shall be in writing and
            shall provide an explanation for all conclusions of law and fact and
            shall include the assessment of costs, expenses, and reasonable
            attorneys' fees. Any such arbitration shall be conducted by an
            arbitrator experienced in the software services industry and shall
            include a written record of the arbitration hearing. The parties
            reserve the right to object to any individual who shall be employed
            by or affiliated with a competing organization or entity. An award
            of arbitration may be confirmed in a court of competent
            jurisdiction. &nbsp;No claims and disputes arising under or relating
            to this provision may be brought by a User more than one (1) year
            after the event giving rise to the cause of action has occurred or
            should diligently been discovered. Notwithstanding the forgoing, the
            parties shall be free to bring a request for injunctive relief
            before a court of competent jurisdiction in the event of immediate
            and irreparable harm.
          </p>
          <br />
          <br />
          <p>
            ‍
            <strong>
              <br />
              SECTION IX: MISCELLANEOUS
            </strong>
          </p>
          <p>9.1. Customer Service.</p>
          <p> </p>
          <p>
            Should you have any questions, comments or concerns regarding the
            Services, customer service may be contacted at any time via
            contact@moonalgo.com or written post by contacting us at the address
            first set forth above. Customer service hours are from Monday to
            Friday, 9 a.m. to 5 p.m. Mountain Time, federal holidays excluded.
            We strive to return all customer service inquires within forty-eight
            (48) business hours.{' '}
          </p>
          <p> </p>
          <br />
          <br />
          <p>9.2. Affiliate Disclosure.</p>
          <p> </p>
          <p>
            We may have an affiliate relationship with third parties and
            affiliates to whose products and/or services we link and promote
            through the Services. Because of this relationship we may earn a
            commission on products purchased by a User from a third-party
            affiliate.
          </p>
          <p> </p>
          <br />
          <br />
          <p>9.3. Authority. </p>
          <br />
          <p>
            Each Party represents and warrants to the other that it has full
            power and authority to enter into this Agreement and that it is
            binding upon such Party and enforceable in accordance with its
            Terms.
          </p>
          <br />
          <br />
          <br />
          <p>9.4. Waiver. </p>
          <br />
          <p>
            Any waiver of a right under these Terms of Use shall only be
            effective if agreed or declared in writing. A delay in exercising a
            right or the non-exercise of a right shall not be deemed a waiver
            and shall not prevent a party from exercising that right in the
            future. The rights and remedies herein provided are cumulative and
            not exclusive of any rights and remedies provided by law.
          </p>
          <br />
          <br />
          <br />
          <p>9.5. Force Majeure. </p>
          <br />
          <p>
            We shall not be bound to meet any obligation if prevented from doing
            so as a consequence of acts of God or force majeure, including but
            not limited to measures taken or imposed by any government or public
            authority or in case of any other event beyond our control,
            including but not limited to natural disasters (such as storm,
            hurricane, fire, flood, earthquake), pandemics, war, civil unrest,
            terrorist activities, states of emergency, government sanctions,
            embargos, nationalizations, strikes and breakdowns of public
            utilities (such as of electricity or telecommunication services). We
            shall use all reasonable efforts to notify you of the circumstances
            causing the delay and to resume performance as soon as possible,
            both without undue delay.
          </p>
          <br />
          <br />
          <br />
          <p>9.6. Assignment. </p>
          <br />
          <p>
            We shall have the right to assign and/or transfer these Terms of Use
            and our rights and obligations hereunder to any third party after
            notifying you as provided for herein. You agree and acknowledge that
            you shall not assign or transfer its rights or sub-contract or
            delegate the performance of any of its obligations under these Terms
            of Use without our prior written consent in our sole and exclusive
            discretion.
          </p>
          <br />
          <br />
          <br />
          <p>9.7. Rights of Third Parties. </p>
          <br />
          <p>
            These Terms do not give any right to any third party unless
            explicitly stated herein.
          </p>
          <br />
          <br />
          <br />
          <p>9.8. Relationship of the Parties. </p>
          <br />
          <p>
            The parties are independent contractors under these Terms, and
            nothing herein shall be construed to create a partnership, joint
            venture or agency relationship between them. Neither party has
            authority to enter into Terms of any kind in the name of the other
            party.{' '}
          </p>
          <br />
          <br />
          <br />
          <p>9.9. Severability.</p>
          <br />
          <p>
            If any part of this Agreement is determined to be invalid or
            unenforceable by applicable law, then the invalid or unenforceable
            provision will be deemed superseded by a valid, enforceable
            provision that most closely matches the intent of the original
            provision and the remainder of this Agreement will continue in
            effect.
          </p>
          <br />
          <br />
          <br />
          <p>9.10. Survival.</p>
          <br />
          <p>
            The following provisions shall survive any termination or expiration
            of this Agreement: Sections VI, VII, and VIII.
          </p>
          <br />
          <br />
          <br />
          <p>9.11. Notices.</p>
          <br />
          <p>
            Except as explicitly stated otherwise, any notices shall be given by
            postal mail to our registered agent and in the case of any User, to
            the email address you provide to us (either during the registration
            process or when your email address changes). Notice shall be deemed
            given twenty-four (24) hours after email is sent, unless the sending
            party is notified that the email address is invalid.
            &nbsp;Alternatively, we may give you notice by certified mail,
            postage prepaid and return receipt requested, to the address
            provided to us upon Account registration. In such case, notice shall
            be deemed given three days after the date of mailing.
          </p>
        </div>
      </div>
    </Layout>
  );
}
